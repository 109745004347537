import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

import { ButtonMenu } from "../components/Buttons"

const Navlink = ({ name, to }) => {
  return (
    <AnchorLink
      to={to}
      title="Nexpa | MCH"
      className={`navbar px-2 lg:px-0 lg:text-center transition ease-in-out delay-50 lg:hover:-translate-y-0.5 lg:hover:scale-105 hover:font-bold duration-300`}
    >
      {name}
    </AnchorLink>
  )
}

const Navbar = () => {
  const [display, setDisplay] = useState("hidden")
  return (
    <>
      <div
        onClick={() =>
          display === "hidden" ? setDisplay("block") : setDisplay("hidden")
        }
      >
        <ButtonMenu display={display} />
      </div>
      <div
        className={`${display} lg:block lg:text-sm xl:text-base z-10 font-semi flex flex-col fixed top-2 right-2 lg:sticky lg:top-0 lg:right-0 bg-white rounded-lg shadow-md lg:rounded-none w-80 lg:w-full xl:w-full lg:mx-auto gap-4 p-4 lg:px-2 lg:py-2 xl:mb-8`}
      >
        <div className="pt-6 pb-2 lg:py-4 flex flex-col gap-5 lg:flex-row lg:justify-between xl:w-3/4 xl:mx-auto">
          {/* <Navlink name="Conoce Más" to="/#video" /> */}
          <Navlink name="Visita Nexpa" to="/#nexpa" />
          <Navlink name="Nuestros Servicios" to="/#services" />
          <Navlink name="Las Cabañas" to="/cabin/choose" />
          <Navlink name="Actividades" to="/#enjoy" />
          <Navlink name="Cómo Llegar" to="/#location" />
          <Navlink name="Pronóstico de Surf" to="/#forecast" />
          <Navlink name="Contacto" to="/#footer" />
        </div>
      </div>
      {/* <div className="h-16 mb-1 z-0 lg:h-4"></div> */}
    </>
  )
}

export default Navbar
