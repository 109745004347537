import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { IconFacebook, IconInstagram } from "../atoms/Icons"

const Footer = () => {
  return (
    <div
      id="footer"
      className="flex flex-col items-center justify-center gap-1 py-1 h-64 lg:h-72 bg-main text-white"
    >
      <div className="flex flex-col items-center gap-4 my-auto">
        <p className="highlight text-white">Contactanos</p>
        <a href="https://wa.me/527531154050">
          <p className="text-white hover:scale-110 hover:underline">
            +52 753 1154050
          </p>
        </a>

        <div className="flex items-center gap-4 pt-4">
          <a href="https://www.instagram.com/nexpacabanas/?hl=es-la">
            <IconInstagram />
          </a>
          <a href="https://www.facebook.com/nexpacabanas.martha">
            <IconFacebook />
          </a>
        </div>
      </div>

      <div className="flex items-center z-10 py-2 scale-90">
        <p className="font-semibold tracking-widest my-auto text-white mt-2">
          Surf & WebDesign{" "}
        </p>
        <a href="https://funksion.site">
          <StaticImage
            height={14}
            src="../images/logoF.png"
            alt="funksion logo"
            className="mt-2 ml-2"
          />
        </a>
      </div>
    </div>
  )
}

export default Footer
